import React, { useEffect } from 'react'
import { loginAs } from '../services/auth'

export default (props) => {
  const token = props.params['*'] || ''

  useEffect(() => {
    loginAs(token)
  }, [token])

  return <div />
}
